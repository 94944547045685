<template>
  <div class="fias-search-view">
    <esmp-select
      disabled
      placeholder="Субъект"
    />
    <esmp-select
      disabled
      placeholder="Населенный пункт"
      class="mt-10"
    />
    <div class="fias-search-view__wrapper mt-10">
      <esmp-select
        disabled
        placeholder="Улица"
      />
      <esmp-select
        disabled
        placeholder="Дом"
        class="ml-10"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiasSearchView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    placeholder() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
  },
};
</script>

<style lang="scss">
.fias-search-view {
  &__wrapper {
    display: flex;
  }
}
</style>
